import React, { useEffect, useState, useContext, createContext } from 'react';

import { login, logout, signup, getActiveUser } from '../utils/utils'

interface Auth {
  user: User | null,
  handleLogin: any,//({string}) => void,
  handleSignUp: any,//({string}) => void,
  handleLogout: () => void
}

interface Credentials {
  email: string,
  password: string,
  name?: string
}

interface SignUpCredentials {
  email: string,
  password: string,
  name: string
}


interface User {
  email: string,
  name?: string
}

const defaultAuthContext: Auth = {
  user: null,
  handleLogin: () => {},
  handleSignUp: () => {},
  handleLogout: () => {}
}

const AuthContext = createContext<Auth>(null!);

export const AuthProvider = ({ children } : { children: any}) => {
  const [user, setUser] = useState<User | null>(null);

  const setActiveUser = async () => {
    const activeUser = await getActiveUser()
    setUser(activeUser)
  }

  const handleLogin = async (credentials: Credentials) => {
    const response = await login(credentials)
    if (response && response.user) {
      setUser(response.user)
      return true
    }
    return false
  }

  const handleSignUp = async (credentials: SignUpCredentials) => {
    const response = await signup(credentials)
    if (response && response.user) {
      setUser(response.user)
      return true
    }
    return false
  }

  const handleLogout = async () => {
    const response = await logout()
    if (response) setUser(null)
  }

  const value = {
    user: user,
    handleLogin: handleLogin,
    handleSignUp: handleSignUp,
    handleLogout: handleLogout
  };

  useEffect(() => {
    setActiveUser()
  }, [])

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

