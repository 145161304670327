import React, { useState, useContext, createContext } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";

import { AuthProvider } from './common/auth'

import AuthView from './views/auth/auth'
import { ErrorElement } from './common/error'
import BoardView, { loader as boardLoader } from './views/board/board';
import TileView, { loader as tileLoader } from './views/tile/tile'

import './index.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(
  createRoutesFromElements(
      <Route path='/' errorElement={<ErrorElement />}>
        <Route path='auth' element={<AuthView />}></Route>
        <Route path='board/:id' element={<BoardView />} loader={boardLoader}></Route>
        <Route path='tile/:id' element={<TileView />} loader={tileLoader}></Route>
      </Route>
))

root.render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
)
