import React from 'react';
import { useNavigate, useLoaderData } from 'react-router-dom';

import { getGraphqlUrl,
         getDisplayTimeFromEpoch,
         getInitialsFromFullName,
         handleGraphqlErrors } from '../../utils/utils'

import { Navbar } from '../../common/plop'
import { Renderer } from '../../views/renderer/renderer'

import './board.css';
import chat from '../../assets/chat.svg'
import plusIcon from '../../assets/plus.svg'
import layersIcon from '../../assets/layers.svg'

interface Tile {
  id: number,
  title: string,
  preview: string,
  body: string,
  comment_count?: number,
  updatedAt: string,
  user: { name: string },
  _count: { versions: number},
  versions: {
    _count: { stubs: number }
  }
}

export async function loader({ params }: { params: any}) {
  const id = params.id
  const urlString = getGraphqlUrl()
  const response = await fetch(urlString, {
    method: 'POST',
    credentials: 'include',
    cache: 'force-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        {
          board (id: ${id}) {
            id
            title
            tiles {
              id
              title
              body
              preview
              updatedAt
              user {
                name
              }
              _count {
                versions
              } 
            }
          }
        }
      `
    }),
  })
  const { data, errors } = await response.json()
  const { board } = data

  handleGraphqlErrors(errors)
  return board
}


function BoardTile ({tile} : {tile: Tile}) {
  const navigate = useNavigate()
  const user = tile.user

  return (
    <div className="tile" onClick={() => navigate(`/tile/${tile.id}`)}>
      <div className="tile__preview">
          <Renderer source={tile.preview} />
      </div>
      <div className="tile__body">
         <div className="tile__title">
            { tile.title }
         </div>
         <div className="ink50 body-3 tile__description">
            { tile.body }
         </div>
         <div className="ink40 tile__meta">
            <div className="metadata tile__meta-user">
              { getInitialsFromFullName(user.name) }
            </div>
            <div className="ink40 tile__meta-username">
              { tile.user.name }
            </div>
            <div className="neutral70 tile__meta-divider">·</div>
            <div className="ink40 tile__meta-updated-at">
              { getDisplayTimeFromEpoch(tile.updatedAt) }
            </div>
            <div className="neutral70 tile__meta-divider">·</div>
            <div className="tile__meta-comments">
              <img src={layersIcon} alt="Icon indicating number of versions for this tile."/>  
              <div className="tile__meta-comment-count">{tile?._count.versions}</div>
            </div> 
            <div className="neutral70 tile__meta-divider">·</div>
            <div className="tile__meta-comments">
              <img src={chat} alt="Icon indicating number of comments for this tile."/>  
              <div className="tile__meta-comment-count">count</div>
            </div>
         </div>
      </div>
    </div>
  )
}

function BoardTileSkeleton () {
  return (
    <div className="board__tile-skeleton">
      <div className="board__tile-skeleton-content">
        <img className="board__tile-skeleton-btn" src={plusIcon} alt="Empty tile icon, click to create."/>
        <div className="ink30 metadata-sm-btn">
          Create New Project
        </div>
      </div>
    </div>
  )
}

function BoardTiles ({ tiles }: {tiles: Tile[]}) {
  return (
    <div className="board__dashboard">
      {tiles.map((tile: Tile, idx: number) => {
        return (<BoardTile tile={tile} key={idx} />)
      })}
     
       <BoardTileSkeleton />
    </div>
  )
}

function BoardInfo ({title} : { title: string }) {
  return (
    <div className="board__info">
      <div className="board__avatar">

      </div>

      <div className="ink60 title-1 board__name">
        { title }
      </div>
    </div>
  )
}


function BoardView() {
  const board: any = useLoaderData();
  const tiles = board?.tiles || []

  return (
    <div className="page__view">
      <Navbar />
      <div className="board__view">
        <BoardInfo title={board.title}/>
        <BoardTiles tiles={tiles} />
      </div>
   </div>
  );
}

export default BoardView;
