import React, { useState, useEffect, useRef } from 'react'
import { Navigate, useRevalidator } from 'react-router-dom';

import { useAuth } from '../../common/auth'
import { createTile, createVersion, uploadFile } from '../../utils/utils'

import './uploader.css'

import linkIcon from '../../assets/link.svg'
import uploadIcon from '../../assets/upload.svg'

function MetaForm ({tile=null, title, setTitle, description, setDescription, closeModal, handleSubmit, 
                    readyToSubmit} : 
                   {tile?: number|null, title: string, setTitle: (t: string) => void, 
                    description: string, setDescription: (d: string) => void,
                    closeModal: () => void, handleSubmit: () => void, readyToSubmit: boolean}) {
  return (
    <>
      <div className="uploader__divider-container">
        <div className="uploader__divider"></div>
        <div className="ink30 body-2 uploader__divider-name">Details</div>
        <div className="uploader__divider"></div>
      </div>

      {!tile && (
      <>
        <label className="ink50 subtitle-4 uploader__form-label">
          Title
        </label>
 
        <input className="body-2 uploader__form-input" type="text" 
               placeholder="Tile name" value={title} onChange={(e) => setTitle(e.target.value)}/>
      </>)
      }

      <label className="ink50 subtitle-4 uploader__form-label">
        Description
      </label>
 
      <textarea className="body-2 uploader__form-textarea" rows={5}
                placeholder="Tile description" value={description}
                onChange={(e) => setDescription(e.target.value)}>

      </textarea>

      <div className="uploader__form-meta-btns">
        <button className="btn btn-secondary uploader__form-meta-cancel"
                onClick={closeModal}>
          Cancel
        </button>

        <button className="btn btn-primary uploader__form-meta-submit"
                disabled={!readyToSubmit} onClick={handleSubmit}>
          Plop
        </button>
      </div>
    </> 
  )
}

export function Uploader ({closeModal, tile=null} : {tile?: number|null, closeModal: () => void}) {
  const { user } = useAuth()

  const [ formPage, setFormPage ] = useState('file')
  const [ url, setUrl ] = useState('')
  const [ key, setKey ] = useState('')
  const [ title, setTitle ] = useState('')
  const [ description, setDescription ] = useState('')
  const [ srcFile, setSrcFile ] = useState<File | null>(null)
  const [ readyToSubmit, setReadyToSubmit ] = useState(false)

  const revalidator = useRevalidator()
  const formRef = useRef<HTMLInputElement>(null)
  const fileRef = useRef<HTMLInputElement>(null)

  const handleSubmit = async () => {
    if (tile) {
      const data = {
        key: key,
        title: title,
        body: description,
        tile: tile ,
        url: url,
      }
      //const created: any = await createVersion(data)
      await createVersion(data)
    } else {
      const data = {
        title: title,
        body: description,
        board: 1,
        newVersion: {
          key: key,
          body: description,
          url: url
        }
      }
      //const created: any = await createTile(data)
      await createTile(data)
    }

    revalidator.revalidate()
    closeModal()
  }

  const handleFileClick = () => {
    fileRef?.current?.click()
  }

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files.length) {
      const source = files[0]
      setTitle(source.name)
      setSrcFile(source)
      setFormPage('meta')
      const response = await uploadFile(source)
      console.log(response)
      if (response?.key) {
        setKey(response.key)
        setReadyToSubmit(true)
      }
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if((e.key === 'Enter')){
      setFormPage('meta')
    }
  }

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value)
    if (e.target.value.length > 0) {
      setFormPage('link')
      setReadyToSubmit(true)
    } else {
      setFormPage('file')
      setReadyToSubmit(false)
    }
  }

  const handleOutsideClick = (e: TouchEvent | MouseEvent) => {
    if (formRef.current && 
        !formRef.current.contains(e.target as Node))
      closeModal()
  }

  const setFormClass = () => {
    if ((formPage === 'meta') && (srcFile !== null)) {
      return ' uploader__form--full--selected'
    } else if (formPage === 'meta') {
      return ' uploader__form--full'
    } else if (formPage === 'link') {
      return ' uploader__form--link'
    }
    return ''
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [])

  if (!user) return <Navigate to="/auth" replace />

  return (
    <div className="uploader">
      <div className={"uploader__form--file" + setFormClass()} ref={formRef}>
          <input className="uploader__file" type="file" onChange={handleChange} ref={fileRef} />
          {!srcFile && <div className="uploader__form-link">
            <img className="uploader__link-icon" src={linkIcon} alt="Link icon." />
            <input className="ink30 body-2 uploader__form-link-input" 
                   placeholder="Paste or drop link" onKeyPress={handleKeyPress} 
                   onChange={handleUrlChange} value={url} />
          </div>}
          { srcFile ? (
            <div className="uploader__form-file uploader__form-file--selected">
              <div>{srcFile.name}</div>
            </div>
          ) : (
            <div className="uploader__form-file">
              <img src={uploadIcon} alt="Upload files icon." />
              <div className="ink60 subtitle-3">
                Upload Files
              </div>
              <div className="ink40 body-2">
                Plop files directly here or 
                <span className="uploader__file-browse" 
                      onClick={handleFileClick}>
                  browse
                </span>
                from your device
              </div>
            </div>)
          }

      { ((formPage === 'meta')  || (formPage === 'link')) && (
        <MetaForm tile={tile} closeModal={closeModal} handleSubmit={handleSubmit} title={title} 
                  setTitle={setTitle} description={description} setDescription={setDescription}
                  readyToSubmit={readyToSubmit} />
      )}
      </div>
    </div>
  )
}
