import React, { useState, useEffect, useRef } from 'react'
import { getSanitizedUrl } from '../../utils/utils'
import ColorThief from "colorthief";

import './renderer.css'

function ImageRenderer ({source} : {source: string}) {
  const bgRef = useRef<HTMLDivElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const handleCanvasClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (canvasRef.current) {
      const rect = canvasRef.current.getBoundingClientRect();
      const ctx = canvasRef.current.getContext('2d')
      if (ctx) {
        let x = (e.clientX - rect.left)    * window.devicePixelRatio
        let y = (e.clientY - rect.top) * window.devicePixelRatio
        ctx.beginPath();
        ctx.arc(x, y, 20, 0, 2 * Math.PI);
        ctx.fillStyle = 'red'
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'black'
        ctx.fill();
        ctx.stroke()
      }
    }
  }

  const setResizeHandler = () => {
    window.addEventListener('resize', draw)
  }

  const draw = () => {
    setRendererBackground()
    if (imgRef.current && canvasRef.current) {
      const canvas = canvasRef.current
      const image = imgRef.current
      const ctx = canvasRef.current.getContext('2d')
      if (ctx) {
        const boundingRect = canvas.getBoundingClientRect();
        const canvasWidth = boundingRect.width * window.devicePixelRatio
        const canvasHeight = boundingRect.height * window.devicePixelRatio
        ctx.canvas.width = canvasWidth
        ctx.canvas.height = canvasHeight
        let scale = Math.min(canvasWidth / image.width, canvasHeight / image.height)
        console.log(window.devicePixelRatio)
        let width = image.width * scale
        let height = image.height * scale
        let x = canvasWidth / 2 - width / 2;
        let y = canvasHeight / 2 - height / 2;

        ctx.drawImage(image, x, y, width, height)
      }
    }
  }

  const handleImageLoad = () => {
    draw()
    setResizeHandler()
  }

  const setRendererBackground = () => {
    if (bgRef?.current && imgRef?.current) {
      const colorThief = new ColorThief();
      const color = colorThief.getColor(imgRef.current);
      bgRef.current.style.backgroundColor = "rgb(" + color + ")";
    }
  }

  return ( 
    <div className="renderer__view" ref={bgRef}>
      <img className="renderer__img" src={source} loading="lazy" alt="Main user-uploaded tile content."/>
    </div>
  )

  /*
  return (
    <div className="renderer__view" ref={bgRef}>
      <canvas className="renderer__canvas" width="100%" height="100%" ref={canvasRef}
              onClick={handleCanvasClick} data-paper-resize>

      </canvas>
      <img className="renderer__img" src={source} ref={imgRef} 
           onLoad={handleImageLoad}/>
    </div>
  )*/
}

function PdfRenderer ({source} : {source: string}) {
  return (
    <embed className="renderer__content renderer__content--text" type="application/pdf" 
           src={source} />
  )
}

function AudioRenderer({source} : {source: string}) {
  return (
    <div className="renderer__view">
      <audio className="renderer__content--audio" src={source} controls={true} />
    </div>
  )
}

function VideoRenderer({source} : {source: string}) {
  return (
    <div className="renderer__view renderer__bg--dark">
      <video className="renderer__content--video" src={source} controls={true} />
    </div>
  )
}

function FrameRenderer({source} : {source: string}) {
  return (
    <div className="renderer__view">
      <iframe title="Plop Tile Content" className="renderer__content--frame" src={source}></iframe>
    </div>
  )
}

function RendererDownload ({source} : {source: string}) {
  const linkRef = useRef<HTMLAnchorElement>(null)

  const downloadFile = () => {
    if (linkRef.current) linkRef.current.click()
  }

  return (
    <div className="renderer__view renderer__bg--light">
      <div className="renderer__download">
        <div className="body-3 renderer__download-message">
          We don't have a way to render this kind of file yet, but you can download it by clicking on the button below.
        </div>
        <button className="metadata-sm-btn btn btn-sm btn-primary renderer__download-btn" onClick={downloadFile}>
          Download
        </button>
        <a className="renderer__download-link" href={source} 
           ref={linkRef}>Download File</a>
      </div>
    </div>
  )
}

function RendererView ({source, contentType} : {source: string, contentType: string}) {
  switch (contentType) {
    case 'image/png':
    case 'image/jpeg':
    case 'image/apng':
    case 'image/avif':
    case 'image/gif':
    case 'image/svg+xml':
    case 'image/webp':
      return <ImageRenderer source={source} /> 
    case 'audio/mpeg':
    case 'audio/wav':
    case 'audio/ogg':
      return <AudioRenderer source={source} />
    case 'video/mp4':
    case 'video/webm':
    case 'video/ogg':
      return <VideoRenderer source={source} />
    case 'application/pdf':
    case 'text/plain':
      return <PdfRenderer source={source} />
    case 'text/html':
      return <FrameRenderer source={source} />
    default:
      return <RendererDownload source={source} />
  }
}

function RendererEmpty () {
  return (
    <div className="renderer__placeholder"></div>
  )
}

export function Renderer ({ source } : { source: string}) {
  const [ contentType, setContentType ] = useState<string | null>(null)
  const [ endpoint, setEndpoint ] = useState<string | null>(null)

  useEffect(() => {
    const url = source ? new URL(getSanitizedUrl(source)) : null

    if (url) {
    setEndpoint(url.toString())
      fetch(url)
      .then(res => {
        console.log(res)
        const contentType = res.headers.get('content-type')
        setContentType(contentType)
      })
      .catch((error) => {
        console.log(error)
        setContentType('text/html')
      });
    }
  }, [source])

  if (!(endpoint && contentType)) return <RendererEmpty />

  return (
    <div className="renderer">
      <RendererView source={endpoint} contentType={contentType} />
    </div>
  )
}
