import React, { useState } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'

import { useAuth } from '../../common/auth'

import { Navbar } from '../../common/plop'

import './auth.css'

function WaitlistForm () {
  const instructions = `
We’re working hard to get Plop ready for launch!

Leave your email and we’ll get back to you as soon as possible. We can’t wait to have you join!`
  return (
    <div className="signup__form">
      <div className="ink60 body-2 signup__instructions">
        { instructions }
      </div>

      <label className="ink50 subtitle-4 signup__label">
        Email
      </label>

      <div className="signup__input-group">
        <input className="ink30 body-2 signup__input" type="email" 
               placeholder="Email address" />
        <button className="btn btn-primary">
          Join waiting list
        </button>
      </div>
    </div>
  )
}

function SignUpForm () {
  const navigate = useNavigate()
  const { handleSignUp, user } = useAuth()
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ name, setName ] = useState('')

  const handleSubmit = async () => {
    const loggedIn = await handleSignUp({
      email: email, 
      password: password,
      name: name
    })
    if (loggedIn) navigate('/board/1')
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if((email !== '') && (password !== '') && (name !== '') && (e.key === 'Enter')){
      handleSubmit()
    }
  }

  return (
    <div className="login__form">
      <div className="ink60 title-1 login__header">
        Welcome!
      </div>

      <label className="ink50 subtitle-4 login__label">
        Email
      </label>
      <input className="ink30 body-2 login__input" 
             placeholder="Email address" type="email" 
             onKeyPress={handleKeyPress}
             value={email} onChange={(e) => setEmail(e.target.value)} />
 
      <label className="ink50 subtitle-4 login__label">
        Password
      </label>
      <input className="ink30 body-2 login__input" 
             placeholder="Password" type="password" 
             onKeyPress={handleKeyPress}
             value={password} onChange={(e) => setPassword(e.target.value)} />
      
    <label className="ink50 subtitle-4 login__label">
        Name
      </label>
      <input className="ink30 body-2 login__input" 
             placeholder="Password" type="password" 
             onKeyPress={handleKeyPress}
             value={name} onChange={(e) => setName(e.target.value)} />
   
      <button className="btn btn-primary login__btn" disabled={!(email && password && name)}
              onClick={handleSubmit} >
        Sign In
      </button>
    </div>
  )
}


function LoginForm () {
  const navigate = useNavigate()
  const { handleLogin, user } = useAuth()
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')

  const handleSubmit = async () => {
    const loggedIn = await handleLogin({email: email, password: password})
    if (loggedIn) navigate('/board/1')
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if((email !== '') && (password !== '') && (e.key === 'Enter')){
      handleSubmit()
    }
  }


  return (
    <div className="login__form">
      <div className="ink60 title-1 login__header">
        Welcome!
      </div>

      <label className="ink50 subtitle-4 login__label">
        Email
      </label>
      <input className="ink30 body-2 login__input" 
             placeholder="Email address" type="email" 
             onKeyPress={handleKeyPress}
             value={email} onChange={(e) => setEmail(e.target.value)} />
 
      <label className="ink50 subtitle-4 login__label">
        Password
      </label>
      <input className="ink30 body-2 login__input" 
             placeholder="Password" type="password" 
             onKeyPress={handleKeyPress}
             value={password} onChange={(e) => setPassword(e.target.value)} />
      
      <button className="btn btn-primary login__btn" disabled={!(email && password)}
              onClick={handleSubmit} >
        Sign In
      </button>
    </div>
  )
}

export default function AuthView () {
  const navigate = useNavigate()
  const { user } = useAuth()
  console.log(user)

  if (user) return <Navigate to="/board/1" replace />;


  return (
    <div className="page__view">
      <Navbar />
      <div className="auth__view">
        <div className="auth__forms">
          <LoginForm />
        </div>
      </div>
    </div>
  )
}
