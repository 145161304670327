import { json } from 'react-router-dom'

/* --- Data Utils ---*/
export function getApiUrl () : URL {
  return new URL((process.env.NODE_ENV === 'production') ? 'https://api.plopped.io' : 'http://localhost:3000')//process.env.REACT_APP_API_URL || 'http://localhost:3000')
  //return new URL(window.location.protocol + "//" + window.location.hostname + ':3000')
}

export function getGraphqlUrl () : URL {
  return new URL('/graphql', getApiUrl())
}

/* --- Auth Utils --- */
export async function getActiveUser () {
  const urlString = new URL('/me', getApiUrl())
  const response = await fetch(urlString, {
    method: 'GET',
    credentials: 'include'
  })
  //if (!response.ok) return null
  const { user } = await response.json()
  return user
}

export async function login (credentials: { email: string, password: string }) {
  const urlString = new URL('/login', getApiUrl())
  const { email, password } = credentials
  const response = await fetch(urlString, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      password: password
    })
  })
  if (!response.ok) return undefined//throw new Error("HTTP status " + response.status);
  const data = await response.json()
  return data
}

export async function signup (credentials: { email: string, password: string, name: string }) {
  const urlString = new URL('/signup', getApiUrl())
  const { email, password, name } = credentials
  const response = await fetch(urlString, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      password: password,
      name: name
    })
  })
  if (!response.ok) return undefined//throw new Error("HTTP status " + response.status);
  const data = await response.json()
  return data
}

export async function logout () {
  const urlString = new URL('/logout', getApiUrl())
  const response = await fetch(urlString, {
    method: 'POST',
    credentials: 'include'
  })
  if (response.ok) return true
  return false
}

/* --- File Data Utils --- */
export async function uploadFile (file: File) {
  const urlString = new URL('/upload', getApiUrl())
  const formData = new FormData()
  formData.append('file', file)
  const response = await fetch(urlString, {
    method: 'POST',
    credentials: 'include',
    body: formData
  })
  console.log(response)
  if (!response.ok) return null
  const payload = await response.json()
  return payload
}

/* --- Data Model Utils --- */
export async function createTile (tile : { title: string, body: string, board: number, newVersion?: {url?: string, key?: string, body: string}}) {
  const response = await fetch(getGraphqlUrl(), {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        mutation {
          createTile (data: {
            title: "${tile.title}",
            body: "${tile.body}", 
            board: ${tile.board},
            ${tile.newVersion ? 
              `
              newVersion: {
                key: "${tile.newVersion.key}",
                body: "${tile.newVersion.body}", 
                url: "${tile.newVersion.url}",
              }` : ''
            }
          }) {
	        id
            title
            body
            updatedAt
            user {
              name
            }
          }
        }
      `
    }),
  })
  const {data} = await response.json()
  return data
}

export async function deleteTile(id: number) {
  const response = await fetch(getGraphqlUrl(), {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        mutation {
          deleteTile (id: ${id}) {
	        id
            title
            body
            updatedAt
            user {
              name
            }
          }
        }
      `
    }),
  })
  const { data, errors } = await response.json()
  handleGraphqlErrors(errors)
  return data.deleteTile
}

export async function createComment (comment : { body: String, version: number}) {
  const response = await fetch(getGraphqlUrl(), {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        mutation {
          createStub (data: {body: "${comment.body}", version: ${comment.version}}) {
	        id
            body
            createdAt
            user {
              name
            }
          }
        }
      `
    }),
  })
  const { data, errors } = await response.json()
  handleGraphqlErrors(errors)

  return data.createStub
}

export async function createVersion (version : { title: string, body: string, url?: string, key?: string, tile: number }) {

  const response = await fetch(getGraphqlUrl(), {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        mutation {
          createVersion (data: {
            title: "${version.title}",
            body: "${version.body}", 
            tile: ${version.tile},
            key: "${version.key}",
            url: "${version.url}"
        }) {
	        id
            body
            createdAt
            user {
              name
            }
          }
        }
      `
    }),
  })
  const { data, errors } = await response.json()
  handleGraphqlErrors(errors)

  return data.createStub
}

export async function getNotifications () {
  const urlString = getGraphqlUrl()
  const response = await fetch(urlString, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        {
          notifications {
            title
            body
          }
        }
      `
    }),
  })
  const { data, errors } = await response.json()
  if (data?.notifications) return data.notifications

  handleGraphqlErrors(errors)
  return []

}

/* --- Response Utils --- */
export function handleGraphqlErrors (errors: any) {
  console.log(errors)
  if (errors && errors.length) {
    const data = errors[0]
    const detail = {
      status: data.status,
      statusText: data.message
    }
    throw json(data, detail)
  }
  else return false
}

/* --- Formating Utils --- */
export function getSanitizedUrl (url: string) : string {
  const pattern = /^((http|https|ftp):\/\/)/;

  if(!pattern.test(url)) {
    url = "https://" + url;
  }
  console.log(url)
  return url
}

export function getDisplayTimeFromEpoch (epoch: String) : String {
  const formatOptions: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }

  const epochTime = Number(epoch)
  const dateTime = new Date(epochTime)
  const displayTime = new Intl.DateTimeFormat('en-US', formatOptions).format(dateTime)

  return displayTime
}

export function getInitialsFromFullName (name: String | undefined) {
  if (name === undefined) return 'ME'
  const split = name.split(' ')
  const initials = split[0][0] + split[1][0]

  return initials
}

export function getOrdinalFromInt (n: number) {
  const lastDigit = n % 10
  if ((n > 10) && (n < 20)) return (n + 'th')
  if (lastDigit === 1) return (n + 'st')
  if (lastDigit === 2) return (n + 'nd')
  if (lastDigit === 3) return (n + 'rd')
  return (n + 'th')
}
