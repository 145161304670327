import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { Uploader } from '../views/uploader/uploader'

import { useAuth } from '../common/auth'
import { getNotifications, 
         getInitialsFromFullName } from '../utils/utils'

import './ds.css'
import './plop.css'

import logo from '../assets/logo.svg'
import alertIcon from '../assets/alert.svg'

export const Navbar = () => {
  const navigate = useNavigate()

  const accountMenuRef = useRef<HTMLInputElement>(null)
  const notificationsRef = useRef<HTMLDivElement>(null)

  const { user, handleLogout } = useAuth()
  const [ uploadIsActive, setUploadIsActive ] = useState(false)
  const [ accountMenuIsActive, setAccountMenuIsActive ] = useState(false)
  const [ notificationsIsActive, setNotificationsIsActive ] = useState(false)
  const [ notifications, setNotifications ] = useState<any[]>([])

  const toggleNotifications = () => {
    setNotificationsIsActive(!notificationsIsActive)
  }

  const refreshNotifications = async () => {
    const notifications = await getNotifications()
    console.log(notifications)
    setNotifications(notifications)
  }

  const toggleAccountMenu = () => {
    setAccountMenuIsActive(!accountMenuIsActive)
  }

  const handleClickLogout = () => {
    handleLogout()
    navigate(0)
  }

  const handleClickOutside = (e: TouchEvent | MouseEvent) => {
    if (accountMenuRef.current &&
        !accountMenuRef.current.contains(e.target as Node))
      setAccountMenuIsActive(false)
  }

  const handleClickOutsideNotifications = (e: TouchEvent | MouseEvent) => {
    if (notificationsRef.current &&
        !notificationsRef.current.contains(e.target as Node))
      setNotificationsIsActive(false)
  }


  useEffect(() => {
    if (user) refreshNotifications()
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('mousedown', handleClickOutsideNotifications)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('mousedown', handleClickOutsideNotifications)
    }
  }, [user])

  return (
    <>
      { uploadIsActive && 
        <Uploader closeModal={() => setUploadIsActive(false)}/> }
      <div className="navbar">
        <div className="navbar__menu">
          <div className="navbar__logo">
            <img src={logo} />
          </div>
          <div onClick={() => setUploadIsActive(!uploadIsActive)}
               className="metadata-sm-btn btn btn-sm btn-primary navbar__new-btn">
            New plop
          </div>
          <div className="navbar__alerts" onClick={toggleNotifications}
               ref={notificationsRef}>
            <img src={alertIcon} />
            {notificationsIsActive && 
              <div className="navbar__notifications">
                <div className="title-4 navbar__notifications-header">
                  Notifications
                </div>
                {notifications.map((notification, idx) => {
                  return (
                    <div className="navbar__notification" key={idx}>
                      <div className="navbar__notification-icon">
                      </div>
                      <div className="navbar__notification-content">
                        <div className="body-3 navbar__notification-title">
                          { notification?.title }
                        </div>
                        <div className="body-3 navbar__notification-body">
                          { notification?.body && ('"' + notification?.body +'"') }
                        </div>
                      </div>
                      <div className="ink30 navbar__notification-timestamp">
                        asdf
                      </div>
                    </div>
                  )
                })}
              </div>
            }
          </div>
          <div className="navbar__account" onClick={toggleAccountMenu} ref={accountMenuRef}>
            <div className="subtitle-3 navbar__avatar">
              { getInitialsFromFullName(user?.name) }
            </div>
            { accountMenuIsActive && 
              <div className="navbar__account-menu" >
                <div className="navbar__account-option" 
                     onClick={handleClickLogout}>
                  Logout
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
