import React, { useEffect, useState, useRef, Dispatch} from 'react';
import {  useNavigate, useLoaderData } from 'react-router-dom';

import { getGraphqlUrl, createComment,
         deleteTile,
         getDisplayTimeFromEpoch,
         getInitialsFromFullName,
         getOrdinalFromInt,
         handleGraphqlErrors } from '../../utils/utils'

import { useAuth } from '../../common/auth'

import { Navbar } from '../../common/plop'
import { Uploader } from '../../views/uploader/uploader'
import { Renderer } from '../../views/renderer/renderer'

import './tile.css';

import maximizeIcon from '../../assets/maximize.svg'
import sendIcon from '../../assets/send.svg'
import chatIcon from '../../assets/chat.svg'
import ellipsisIcon from '../../assets/ellipsis.svg'
import ellipsisIconLg from '../../assets/ellipsis--lg.svg'
import cancelIcon from '../../assets/cancel.svg'

interface Comment {
  body: string,
  createdAt: string,
  user: {
    initials: string,
    name: string
  }
}

interface Version {
  id: number,
  body: string,
  stubs: Comment[],
  createdAt: string,
  user: {
    initials: string,
    name: string
  }
}

export async function loader({ params }: { params: any}) {
  const id = params.id
  const response = await fetch(getGraphqlUrl(), {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        {
          tile (id: ${id}) {
            id
            title
            preview
            boardId
            versions {
              id
              title
              body
              url
              preview
              user { name }
              createdAt
              stubs {
                body
                createdAt
                user { name } }
            }
         }
        }
      `
    }),
  })
  const { data, errors } = await response.json()
  const { tile } = data
  console.log(tile)

  handleGraphqlErrors(errors)
  return (data)
}


function Version ({version, index, handleClick} : {version: Version, index: number, handleClick: () => void}) {
  const user = version.user
  const commentCount = version?.stubs ? version.stubs.length : 0

  return (
    <div className="tile__version-block">
    <div className="ink60 tile__version" onClick={() => {handleClick()}}>
      <div className="metadata tile__version-avatar">
        { getInitialsFromFullName(user.name) }
      </div>
      <div className="tile__version-contents">
        <div className="tile__version-info">
          <div className="subtitle-4 tile__version-name">
            { user.name }
          </div>
          <div className="ink30 metadata tile__version-timestamp">
            { getDisplayTimeFromEpoch(version.createdAt) }
          </div>
          <div className="tile__version-info-spacer">·</div>
          <div className="metadata tile__version-index">
            {getOrdinalFromInt(index + 1) + ' Plop'} 
          </div> 
          <div className="tile__version-info-spacer">·</div>
          <img src={chatIcon} alt="Chat icon."/>
          <div className="metadata tile__version-comment-count">
            {commentCount}
          </div>
          <img className="icon tile__ellipsis" src={ellipsisIcon} alt="More options icon"/>
        </div>
        <div className="body-3 tile__version-body">
          { version.body }
        </div>
      </div>
    </div>
      <div className="tile__comments">
        { version?.stubs.map((comment: Comment, idx: number) => {
          return (<Comment comment={comment} key={idx} />)
        })}
      </div>
    </div>
  )
}

function Comment ({comment} : {comment: Comment}) {
  const user = comment.user

  return (
    <div className="ink60 tile__comment">
      <div className="metadata tile__comment-avatar">
        { getInitialsFromFullName(user.name) }
      </div>
      <div className="tile__comment-contents">
        <div className="tile__comment-info">
          <div className="subtitle-4 tile__comment-name">
            { user.name }
          </div>
          <div className="ink30 metadata tile__comment-timestamp">
            { getDisplayTimeFromEpoch(comment.createdAt) }
          </div>
          <img className="icon tile__ellipsis" src={ellipsisIcon} alt="More options icon"/>
        </div>
        <div className="body-3 tile__comment-body">
          { comment.body }
        </div>
      </div>
    </div>
  )
}

function TileDetail ({tileId, versions, activeVersion, setActiveVersion} : {tileId: number, versions: Version[], activeVersion: number, setActiveVersion: Dispatch<any>}) {
  const ref = React.useRef<HTMLInputElement>(null)
  const [ cachedVersions, setCachedVersions] = useState<Version[]>(versions)
  const [ commentBody, setCommentBody] = useState('')
  const activeIndex = cachedVersions.findIndex(x => x.id === activeVersion)

  const scrollStubBottom = () => {
    setTimeout(function(){
      ref.current?.scrollTo({top: ref.current?.scrollHeight})
    }, 200);
  }

  const submitComment = async () => {
    setCommentBody('')
    if (activeVersion) {
      const data = {
        body: commentBody,
        version: activeVersion,
      }
      const stub = await createComment(data)
      if (stub) {
        let updatedVersions = cachedVersions
        const index = cachedVersions.findIndex(x => x.id === activeVersion)
        updatedVersions[index].stubs.push(stub)
        setCachedVersions([...updatedVersions])
        scrollStubBottom()
      }
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if((commentBody !== '') && (e.key === 'Enter')){
      submitComment()
    }
  }

  useEffect(() => {
    setCachedVersions(versions)
    scrollStubBottom()
  }, [versions])

  return (
    <div className="tile__detail">
      <div className="tile__versions" ref={ref}>
        { cachedVersions.map((version: Version, idx: number) => {
          return (<Version version={version} key={idx} index={idx} 
                           handleClick={() => setActiveVersion(version.id)} />)
        })}
      </div>

      <div className="tile__input-block">
        <div className="ink40 metadata tile__input-target">
          Send to
          <span className="blue50 metadata">
            {' ' + getOrdinalFromInt(activeIndex + 1) + ' Plop'} 
          </span>
        </div>
        <div className="tile__input">
          <input className="ink30 body-2" type="text" 
                 placeholder="Add a comment. Use @ to mention." value={commentBody} 
                 onKeyPress={handleKeyPress}
                 onChange={(e) => setCommentBody(e.target.value)}/>
          <button className="tile__submit-comment" onClick={submitComment}>
            <img src={sendIcon} alt="Send message icon" />
          </button>
        </div>
      </div>
    </div>
  )
}

function TilePreview ({src}: {src : string}) {
  const [isFullscreen, setIsFullscreen] = useState(false)

  const toggleFullscreen = (url?: string) => {
    setIsFullscreen(!isFullscreen)
  }

  return (
    <div className={"tile__viewer" + (isFullscreen ? " tile__viewer--fullscreen" : "")}>
      <button className="tile__maximize" onClick={() => toggleFullscreen()}>
        <img src={maximizeIcon} alt="maximize frame icon" />
      </button>
      <Renderer source={src} />
   </div>
  )
}

function TileBrowser ({tile} : {tile: any}) {
  const versions = tile.versions
  const [ activeVersion, setActiveVersion ] = useState(tile.versions.length > 0 ? tile.versions.slice(-1)[0].id : null)
  const activeObj = versions.find((version: Version) => version.id === activeVersion)

  useEffect(() => {
    setActiveVersion(tile.versions.length > 0 ? tile.versions.slice(-1)[0].id : null)
  }, [tile.versions.length])


  return (
    <div className="tile__browser">
      <TilePreview src={activeObj.preview} />
      <TileDetail tileId={tile.id} versions={tile.versions} activeVersion={activeVersion} setActiveVersion={setActiveVersion}/>
    </div>
  )
}

function TileMenu ({ boardId, tileId }: { boardId: number, tileId: number }) {
  const navigate = useNavigate()

  const subMenuRef = useRef<HTMLButtonElement>(null)

  const { user } = useAuth()
  const [ uploadIsActive, setUploadIsActive ] = useState(false)
  const [ subMenuIsActive, setSubMenuIsActive ] = useState(false)

  const toggleSubMenu = () => {
    setSubMenuIsActive(!subMenuIsActive)
  }

  const handleClickBack = () => {
    navigate('/board/' + boardId)
  }

  const handleClickDelete = async () => {
    const tile = await deleteTile(tileId)
    if (tile) navigate('/board/' + boardId)
  }

  const handleOutsideClick = (e: TouchEvent | MouseEvent) => {
    if (subMenuRef.current &&
        !subMenuRef.current.contains(e.target as Node))
      setSubMenuIsActive(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [])


  return (
    <>
      { uploadIsActive &&
        <Uploader tile={tileId} closeModal={() => setUploadIsActive(false)}/> }
      <div className="navbar">
        <div className="navbar__menu">
          <div className="tile__menu--left">
            <button className="tile__menu-cancel" onClick={handleClickBack}>
              <img src={cancelIcon} alt="Cancel icon." />
            </button>
          </div>
          <div className="tile__menu--right">
            <div onClick={() => setUploadIsActive(!uploadIsActive)}
                 className="metadata-sm-btn btn btn-sm btn-primary tile__menu-plop">
              Plop to this tile
            </div>
            <button className="tile__menu-more" onClick={toggleSubMenu} 
                    ref={subMenuRef}>
              <img className="tile__menu-more-icon" src={ellipsisIconLg} alt="More options icon."/> 

              { subMenuIsActive && 
                <div className="tile__submenu"  >
                  <div className="tile__submenu-option tile__submenu--delete"
                       onClick={handleClickDelete} >
                    Delete
                  </div>
                </div>
              }

            </button>
          </div>
        </div>
      </div>
    </>

  )
}

function TileFooter () {
  return (
    <div className="tile__bottom">

    </div>
  )
}

function TileView () {
  const { tile }: any = useLoaderData();

  return (
   <div className="tile__view">
      <TileMenu boardId={tile.boardId} tileId={tile.id} />
      <TileBrowser tile={tile}/>
      <TileFooter />
   </div>
  )
}

export default TileView
