import React from 'react'
import { useRouteError } from 'react-router-dom'

import { Navbar } from './plop'

import './error.css'

function Error401 (error: any) {
  return (
    <div className="error__panel">
      <div className="title-2 error__header">
        No access
      </div>
      <div className="body-2">
        It looks like you don't have access to view this page.  Most likely, you're seeing this error because you haven't <a href="/auth">logged in</a> yet, or the owner of this board forgot to set the board to "public" before sharing.
      </div>
    </div> 
  )
}

function Error404 (error: any) {
  console.log(error)
  return (
    <div className="error__panel">
      <div className="title-2 error__header">
        Error 404
      </div>
      <div className="body-2">
        Whoops!  We can't find that page.  Please double check the url, and try again.
      </div>
    </div>
  )
}

function Error (error: any) {
  console.log(error)
  return (
    <div className="error__panel">
      <div className="title-2 error__header">
        Error {error.status || ''}
      </div>
      <div className="body-2">
        { error?.message || 'Error' }
      </div>
    </div> 
  )
}

export function ErrorElement () {
  const error: any = useRouteError();
  let errorPanel = Error(error)
  console.log(error)

  switch (error?.status) {
    case 401:
      errorPanel = Error401(error)
      break 
    case 404:
      errorPanel = Error404(error)
      break
  }
  console.log(error)

  return (
    <div className="page__view">
      <Navbar />
      <div className="error__view">
        {errorPanel} 
      </div>
    </div>
  )
}

